import * as React from "react";
import { Box, Divider, Typography } from "@mui/material";

export function TabPanelContent({ mainHeadline, locations, images }) {
  return (
    <>
      <p>{mainHeadline}</p>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          overFlow: "auto",
        }}
      >
        {locations.map((item, index) => (
          <Box>
            <Divider component="div" role="presentation">
              <Typography variant="h1">{`Übersichtskarte der ${locations[index]}`}</Typography>
            </Divider>
            <img
              src={images[index]}
              width={"100%"}
              alt={`Sensorenkarte ${locations[index]}`}
              sx={{ overFlow: "auto" }}
            />
          </Box>
        ))}
      </Box>
    </>
  );
}
