export const sensorsStaticString = {
  sensorsHeadline: "Starkregenfrühalarmsystem - Sensornetz im gesamten Landkreis Fulda ",
  sensorsDescription1:
    "In der Rubrik „Messnetz“ können Sie den gesamten Aufbau des Sensornetzes im Landkreis Fulda sehen. Mithilfe von 23 gemeindlichen Kartenauszügen lassen sich somit die Standorte der insgesamt rund 190 installierten Sensoren für das Starkregenfrühalarmsystem im osthessischen Landkreis Fulda erkennen. Die Standorte der Sensorik wurden anhand einer Vielzahl von lokalen Geoinformationen und daraus resultierenden Karten gemeinsam mit den Gemeinden sowie Akteuren aus der Siedlungswasserwirtschaft, der Informationstechnik und Rettungskräften eruiert. Um hydraulische Daten zu erhalten, wurden drei verschiedene Sensortypen installiert, die die Stände der Gewässer, die Niederschlagsmenge und Durchflussmengen in Kanälen 24/7 überwachen. Außerdem wurden die bereits bestehenden Pegelstandorte des HLNUG in das Starkregenfrühalarmsystem integriert.",
  sensorsDescription2:
    "Bei der Standortauswahl der Gewässerpegel sehen Sie, dass speziell die kleineren Gewässer in den Fokus gerückt sind und dass hier Hot-Spot-Bereiche ausgewählt wurden, anhand derer sich frühzeitig Veränderungen in der Wasserspiegellage zeigen, die zu einer Flutwelle in den unterhalb gelegenen Ortslagen führen können.",
  sensorsDescriptionLegend1: "Gewässerpegel - hellblauer Punkt ",
  sensorsDescriptionLegend2: "Niederschlagsmessstation - roter Punkt ",
  sensorsDescriptionLegend3: "Kanalmesser - grüner Punkt ",
  sensorsDescriptionLegend4: "HLNUG Pegel - blauer Punkt",
  eichenzellDescription:
    "Um eine möglichst breite Erfassung von Daten und eine daraus resultierende nutzbare Vorwarnzeit im Starkregenfall zu erhalten, wurden im Gemeindegebiet Eichenzell insgesamt 18 Sensoren installiert. Diese setzen sich, wie der statischen Karte zu entnehmen ist, aus 6 Gewässerpegeln (Fließgewässer: Lütter, Fliede, Döllbach, Thalaubach), 6 Niederschlagsmessstationen und 6 Kanaldeckelwächtern zusammen. Im September 2022 ist die Umsetzung der Sensorinstallation für das Starkregenfrühalarmsystem in der Gemeinde Eichenzell erfolgreich beendet worden.",
  ebersburgDescription:
    "Die in den letzten Jahren sehr häufig von Starkregen betroffene Gemeinde Ebersburg wurde mit insgesamt 15 Sensoren ausgestattet. Hierbei handelt es sich um 5 Gewässerpegel (Fließgewässer: Fulda, Schmalnau, Thalaubach, Lütter, Rehbach), 3 Niederschlagsmessstationen und 7 Kanaldeckelwächter, die im Einsatz sind. Die Umsetzung der Installation wurde erfolgreich im September 2022 beendet. Um das Gemeindegebiet Ebersburg über die Gewässerstrecke der „Lütter“ noch gezielter mit Vorwarnungen zu belegen, wurde im Laufe des Jahres 2023 ein weiterer Gewässerpegel an der „Alexander-Schleicher-Straße“ im Ortsteil Poppenhausen montiert.",
  neuhofDescription:
    "Die Pilotkommune Neuhof, die sich durch enge Tallagen und Gewässer mit starkem Gefälle auszeichnet, wurde mit insgesamt mit 11 Sensoren versehen. Hierbei handelt es sich um 1 Gewässerpegel (Fließgewässer: Rehbach), 4 Niederschlagsmessstationen, 6 Kanaldeckelwächter. Bis Mitte Oktober 2022 wurden alle Sensoren angeschlossen.",
  burghaunDescription:
    "In der, im Norden des Landkreises gelegenen, Pilotkommune Burghaun sind 3 Gewässerpegel (Fließgewässer u. a.: Steinbach, Rainbach), 3 Niederschlagsmessstationen und 11 Kanaldeckelwächter installiert worden. Somit kommen in den neun Ortsteilen insgesamt 17 Sensoren zum Einsatz. Die Umsetzung der Installation wurde erfolgreich Anfang Oktober 2022 beendet.",
  regionNordDescription:
    "Die Region Nord beinhaltet die Stadt Hünfeld und die Kommunen Eiterfeld, Rasdorf & Nüsttal. Sie macht mit einer Gesamtfläche von 253 km² rund 18 % des Landkreises Fulda aus. Insgesamt wurden 34 smarte Sensoren installiert, die zukünftig noch vor Eintreten eines Starkregenereignisses Frühwarnungen an die Bevölkerung, Rettungskräfte & Verwaltungen ausgeben werden. Die Sensorik in der nördlichen Region des Landkreises besteht zu 50 % aus Gewässerpegeln, zu 38 % aus Niederschlagsmessstationen und zu 12 % aus Kanaldeckelwächtern, die an neuralgischen Punkten im Kanalnetz angebracht wurden.",
  regionMitteDescription:
    "Mit einer Fläche von insgesamt rund 287 km² beheimatet die Region Mitte insgesamt ca. 106.00 Bürgerinnen und Bürger des Landkreises. Im Projekt Starkregenfrühalarmsystem bilden die Stadt Fulda & die Gemeinden Künzell, Petersberg, Hofbieber und Dipperz somit flächenmäßig die größte Region. Dies spiegelt sich auch in der Anzahl der eingesetzten Sensorik wieder. Insgesamt wurden 21 Gewässerpegel, 9 Niederschlagsmessstationen und 11 Kanaldeckelwächter in den ersten beiden Quartalen 2023 installiert. Dies entspricht einem Anteil von circa 22 % an der landkreisweiten smarten Sensorik für das Starkregenfrühalarmsystem. Eine Registrierung bei der Stadt Fulda und den Gemeinden Petersberg, Künzell, Dipperz und Hofbieber ist bereits jetzt unter der „Starkregen-App“ in allen gängigen Appstores möglich oder unter ",
  regionOstDescription:
    "Die östliche Region beinhaltet die Stadt Tann (Rhön) sowie die beiden Gemeinden Hilders und Ehrenberg (Rhön). In dieser Region wurden insgesamt 5 Gewässerpegel, 3 Niederschlagsmessstationen und 3 Kanaldeckelwächter installiert. Die Pegelmessanlage des Hessischen Landesamtes (HLNUG) in Günthers (Stadt Tann (Rhön)) wurde ebenfalls in das Starkregenfrühalarmsystem integriert.",
  regionWestDescription:
    "Die drei westlich gelegenen Gemeinden Großenlüder, Bad Salzschlirf und Hosenfeld ergeben eine weitere Region. Auf insgesamt 137,7 km² wurden 7 Gewässerpegel, 5 Niederschlagsmessstationen und 4 Kanaldeckelwächter installiert. Die Pegelmessanlagen des Hessischen Landesamtes (HLNUG) in Bad Salzschlirf und Lütterz wurden ebenfalls in das Starkregenfrühalarmsystem integriert.",
  regionSuedDescription:
    "Die in der Rhön gelegene Stadt Gersfeld (Rhön) und die Gemeinden Poppenhausen (Wasserkuppe) und Ebersburg (Pilotkommune 2022) bilden die Region Süd im Projekt Starkregenfrühalarmsystem. Um zukünftig noch vor Eintritt eines Starkregenereignisses die Bevölkerung und Rettungskräfte warnen zu können, wurden insgesamt 9 Gewässerpegel, 4 Niederschlagsmessstationen sowie 7 Kanaldeckelwächter installiert. Die Pegelmessanlage des Hessischen Landesamtes (HLNUG) in Hettenhausen (Stadt Gersfeld) wurde ebenfalls in das Starkregenfrühalarmsystem integriert.",
  regionSuedWestDescription:
    "Die Region Südwest setzt sich aus den Gemeinden Flieden & Kalbach zusammen, sowie den beiden Pilotkommunen Neuhof und Eichenzell. Nachdem die Installation in den Pilotkommunen bereits im Jahr 2022 umgesetzt wurde, wurden die beiden Gemeinden Flieden und Kalbach im zweiten Projektjahr, 2023 ausgestattet. Insgesamt wurden 11 Gewässerpegel, 11 Niederschlagsmessstationen, 15 Kanaldeckelwächter, 1 Gateway-Station (zusätzliche Sendeeinheit zur Datenübertragung) und 2 Niederschlagsmessstationen auf Smartpoles (intelligente Straßenlaterne) in den vier Gemeinden installiert. Die Pegelmessanlage des Hessischen Landesamtes (HLNUG) in Kerzell wurde ebenfalls in das Starkregenfrühalarmsystem integriert.",
};
