import { Dialog } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogActions } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { useTheme } from "@emotion/react";

import starkregenFuldaLogo from "../../assets/images/logos/starkregen-fulda-hor.svg";
import starkregenFuldaLogoPrimary from "../../assets/images/logos/starkregen-fulda-hor-primary.svg";

export function InfoWelcomeDialog({ openWelcomeText, handleCloseWelcomeText }) {
  const theme = useTheme();

  return (
    <Dialog maxWidth="xs" open={openWelcomeText} onClose={handleCloseWelcomeText}>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          direction: "columns",
          m: 2,
        }}
      >
        {" "}
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mr: 2 }}>
            <img
              src={theme.palette.mode === "light" ? starkregenFuldaLogoPrimary : starkregenFuldaLogo}
              style={{
                width: "200px",
              }}
              alt="Starkregen-Fulda Logo"
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography variant="h5">Das Starkregenprojekt des Landkreises wurde erfolgreich abgeschlossen!</Typography>
            <p>
              Sie können ab sofort die Pegelsensoren in Ihrer Kommune abrufen unter <a href="http://www.Starkregen.de">www.Starkregen.de</a>.
              Mit Auswahl Ihrer Gemeinde / Stadt gelangen Sie zu der Echtzeitkarte, welche die Sensorstandorte inklusive Messdaten zeigt.{" "}
              <br /> <br />
              Auch die mobile Version - nämlichen die Starkregen App - ist für Sie kostenfrei in allen gängigen App-Stores verfügbar. Wenn Sie Fragen haben, dann wenden Sie sich gerne per Mail an uns über <a href="mailto:Starkregen@Landkreis-Fulda.de">Starkregen@Landkreis-Fulda.de</a>.
              <br /> <br />
              <strong>Hinweis:</strong> Bitte melden Sie akute Notfälle oder Gefährdungen direkt der Polizei (110) bzw. der Feuerwehr (112).
            </p>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
        <Button onClick={handleCloseWelcomeText} variant="contained">
          Schliessen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
