import * as React from "react";
import { Box, Divider, Typography } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import SensorMapBurghaunHoch from "../assets/images/sensors/Sensorstandorte_Burghaun_20221017_hoch.png";
import SensorMapBurghaunQuer from "../assets/images/sensors/Sensorstandorte_Burghaun_20221017_quer.png";
import SensorMapEbersburgHoch from "../assets/images/sensors/Sensorstandorte_Ebersburg_20221004_hoch.png";
import SensorMapEbersburgQuer from "../assets/images/sensors/Sensorstandorte_Ebersburg_20221004_quer.png";
import SensorMapEichenzellHoch from "../assets/images/sensors/Sensorstandorte_Eichenzell_20221026_hoch.png";
import SensorMapEichenzellQuer from "../assets/images/sensors/Sensorstandorte_Eichenzell_20221026_quer.png";
import SensorMapEiterfeldHoch from "../assets/images/sensors/Sensorstandorte_Eiterfeld_20230510_hoch.png";
import SensorMapEiterfeldQuer from "../assets/images/sensors/Sensorstandorte_Eiterfeld_20230510_quer.png";
import SensorMapHuenfelddHoch from "../assets/images/sensors/Sensorstandorte_Huenfeld_20230517_hoch.png";
import SensorMapHuenfelddQuer from "../assets/images/sensors/Sensorstandorte_Huenfeld_20230517_quer.png";
import SensorMapNeuhofHoch from "../assets/images/sensors/Sensorstandorte_Neuhof_20221026_hoch.png";
import SensorMapNeuhofQuer from "../assets/images/sensors/Sensorstandorte_Neuhof_20221026_quer.png";
import SensorMapNuesttalHoch from "../assets/images/sensors/Sensorstandorte_Nuesttal_20230510_hoch.png";
import SensorMapNuesttalQuer from "../assets/images/sensors/Sensorstandorte_Nuesttal_20230510_quer.png";
import SensorMapRasdorfQuerHoch from "../assets/images/sensors/Sensorstandorte_Rasdorf_20230510_hoch_quer.png";
import SensorMapDipperzQuerHoch from "../assets/images/sensors/Sensorstandorte_Dipperz_20230630_quer.png";
import SensorMapFuldaQuerHoch from "../assets/images/sensors/Sensorstandorte_Fulda_20230630_quer.png";
import SensorMapHofbieberQuerHoch from "../assets/images/sensors/Sensorstandorte_Hofbieber_20230630_quer.png";
import SensorMapKuenzellQuerHoch from "../assets/images/sensors/Sensorstandorte_Künzell_20230630_quer.png";
import SensorMapPetersbergQuerHoch from "../assets/images/sensors/Sensorstandorte_Petersberg_20230630_quer.png";

import SensorMapBadSalzschlirf from "../assets/images/sensors/Sensorstandorte_BadSalzschlirf_20230928_quer.png";
import SensorMapEhrenberg from "../assets/images/sensors/Sensorstandorte_Ehrenberg_20230908_hoch_quer.png";
import SensorMapFlieden from "../assets/images/sensors/Sensorstandorte_Flieden_20230928_quer.png";
import SensorMapGersfeld from "../assets/images/sensors/Sensorstandorte_Gersfeld_20230908_quer.png";
import SensorMapGrosenlueder from "../assets/images/sensors/Sensorstandorte_Großenlüder_20230908_quer.png";
import SensorMapHilders from "../assets/images/sensors/Sensorstandorte_Hilders_20230908_quer.png";
import SensorMapHosenfeld from "../assets/images/sensors/Sensorstandorte_Hosenfeld_20230908_quer.png";
import SensorMapKalbach from "../assets/images/sensors/Sensorstandorte_Kalbach_20230928_quer.png";
import SensorMapTann from "../assets/images/sensors/Sensorstandorte_Tann_20230908_quer.png";

import { InfoWidget } from "../components/info-components/info-widget";
import { ScrollContainer } from "../elements/scroll-container";
import useWindowDimensions from "../providers/window-dimensions-provider";
import { TabPanel } from "../components/tab-panel/tab-panel";
import { sensorsStaticString } from "../assets/strings/static-strings";
import { TabPanelContent } from "../components/tab-panel/tab-panel-content";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function SensorsPage() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { width, height } = useWindowDimensions();
  const difference = width - height;

  return (
    <ScrollContainer>
      <InfoWidget title="Messnetz" icon={<SensorsIcon style={{ marginRight: 10 }} />}>
        <Typography marginBottom={0} variant="h3">
          {sensorsStaticString.sensorsHeadline}
        </Typography>
        <p>{sensorsStaticString.sensorsDescription1}</p>
        <p>
          {sensorsStaticString.sensorsDescriptionLegend1}
          <br />
          {sensorsStaticString.sensorsDescriptionLegend2}
          <br />
          {sensorsStaticString.sensorsDescriptionLegend3}
          <br />
          {sensorsStaticString.sensorsDescriptionLegend4}
        </p>
        <p>{sensorsStaticString.sensorsDescription2}</p>
        <br />
      </InfoWidget>
      <InfoWidget>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Burghaun" {...a11yProps(0)} />
              <Tab label="Ebersburg" {...a11yProps(1)} />
              <Tab label="Eichenzell" {...a11yProps(2)} />
              <Tab label="Neuhof" {...a11yProps(3)} />
              <Tab label="Region Nord" {...a11yProps(4)} />
              <Tab label="Region Ost" {...a11yProps(5)} />
              <Tab label="Region Süd" {...a11yProps(6)} />
              <Tab label="Region Südwest" {...a11yProps(7)} />
              <Tab label="Region West" {...a11yProps(8)} />
              <Tab label="Region Mitte" {...a11yProps(9)} />
            </Tabs>
          </Box>

          {/* Burghaun */}
          <TabPanel value={value} index={0}>
            <p>{sensorsStaticString.burghaunDescription}</p>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                overFlow: "auto",
              }}
            >
              <img
                src={difference > 250 ? SensorMapBurghaunQuer : SensorMapBurghaunHoch}
                width={"100%"}
                alt="Sensorenkarte der Region Burghaun"
                sx={{ overFlow: "auto" }}
              />
            </Box>
          </TabPanel>

          {/* Ebersburg */}
          <TabPanel value={value} index={1}>
            <p>{sensorsStaticString.ebersburgDescription}</p>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                overFlow: "auto",
              }}
            >
              <img
                src={difference > 250 ? SensorMapEbersburgQuer : SensorMapEbersburgHoch}
                width={"100%"}
                alt="Sensorenkarte der Region Ebersburg"
                sx={{ overFlow: "auto" }}
              />
            </Box>
          </TabPanel>

          {/* Eichenzell */}
          <TabPanel value={value} index={2}>
            <p>{sensorsStaticString.eichenzellDescription}</p>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                overFlow: "auto",
              }}
            >
              <img
                src={difference > 250 ? SensorMapEichenzellQuer : SensorMapEichenzellHoch}
                width={"100%"}
                alt="Sensorenkarte der Region Eichenzell"
                sx={{ overFlow: "auto" }}
              />
            </Box>
          </TabPanel>

          {/* Neuhof */}
          <TabPanel value={value} index={3}>
            <p>{sensorsStaticString.neuhofDescription}</p>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                overFlow: "auto",
              }}
            >
              <img
                src={difference > 250 ? SensorMapNeuhofQuer : SensorMapNeuhofHoch}
                width={"100%"}
                alt="Sensorenkarte der Region Neuhof"
                sx={{ overFlow: "auto" }}
              />
            </Box>
          </TabPanel>

          {/* Region Nord */}
          <TabPanel value={value} index={4}>
            <p>{sensorsStaticString.regionNordDescription}</p>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                overFlow: "auto",
              }}
            >
              <Divider component="div" role="presentation">
                <Typography variant="h1">Übersichtskarte der Stadt Hünfeld</Typography>
              </Divider>
              <img
                src={difference > 250 ? SensorMapHuenfelddQuer : SensorMapHuenfelddHoch}
                width={"100%"}
                alt="Sensorenkarte der Region Hünfeld"
                sx={{ overFlow: "auto" }}
              />
              <Divider component="div" role="presentation">
                <Typography variant="h1">Übersichtskarte der Marktgemeinde Eiterfeld</Typography>
              </Divider>
              <img
                src={difference > 250 ? SensorMapEiterfeldQuer : SensorMapEiterfeldHoch}
                width={"100%"}
                alt="Sensorenkarte der Region Eiterfeld"
                sx={{ overFlow: "auto" }}
              />
              <Divider component="div" role="presentation">
                <Typography variant="h1">Übersichtskarte der Gemeinde Nüsttal</Typography>
              </Divider>
              <img
                src={difference > 250 ? SensorMapNuesttalQuer : SensorMapNuesttalHoch}
                width={"100%"}
                alt="Sensorenkarte der Region Nüsttal"
                sx={{ overFlow: "auto" }}
              />
              <Divider component="div" role="presentation">
                <Typography variant="h1">Übersichtskarte der Point-Alpha-Gemeinde Rasdorf </Typography>
              </Divider>
              <img
                src={SensorMapRasdorfQuerHoch}
                width={"100%"}
                alt="Sensorenkarte der Region Rasdorf"
                sx={{ overFlow: "auto" }}
              />
            </Box>
          </TabPanel>

          {/* Region Ost */}
          <TabPanel value={value} index={5}>
            <TabPanelContent
              mainHeadline={sensorsStaticString.regionOstDescription}
              locations={["Stadt Tann(Rhön)", "Gemeinde Hilders", "Gemeinde Ehrenberg (Rhön)"]}
              images={[SensorMapTann, SensorMapHilders, SensorMapEhrenberg]}
            />
          </TabPanel>

          {/* Region Sued */}
          <TabPanel value={value} index={6}>
            <TabPanelContent
              mainHeadline={sensorsStaticString.regionSuedDescription}
              locations={["Stadt Gersfeld(Rhön)", "Gemeinde Ebersburg"]}
              images={[SensorMapGersfeld, SensorMapEbersburgQuer]}
            />
          </TabPanel>

          {/* Region Suedwest */}
          <TabPanel value={value} index={7}>
            <TabPanelContent
              mainHeadline={sensorsStaticString.regionSuedWestDescription}
              locations={["Gemeinde Flieden", "Gemeinde Kalbach", "Gemeinde Neuhof", "Gemeinde Eichenzell"]}
              images={[SensorMapFlieden, SensorMapKalbach, SensorMapNeuhofQuer, SensorMapEichenzellQuer]}
            />
          </TabPanel>

          {/* Region West */}
          <TabPanel value={value} index={8}>
            <TabPanelContent
              mainHeadline={sensorsStaticString.regionWestDescription}
              locations={["Gemeinde Großenlüder", "Gemeinde Bad Salzschlirf", "Gemeinde Hosenfeld"]}
              images={[SensorMapGrosenlueder, SensorMapBadSalzschlirf, SensorMapHosenfeld]}
            />
          </TabPanel>

          {/* Region Mitte */}
          <TabPanel value={value} index={9}>
            <p>
              {sensorsStaticString.regionMitteDescription}{" "}
              <a href="https://app.starkregen.de/register" aria-label="Link zur Registrierung bei app.starkregen.de">
                https://app.starkregen.de/register
              </a>
            </p>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                overFlow: "auto",
              }}
            >
              <Divider component="div" role="presentation">
                <Typography variant="h1">Übersichtskarte der Gemeinde Dipperz</Typography>
              </Divider>
              <img
                src={SensorMapDipperzQuerHoch}
                width={"100%"}
                alt="Sensorenkarte der Region Dipperz"
                sx={{ overFlow: "auto" }}
              />
              <Divider component="div" role="presentation">
                <Typography variant="h1">Übersichtskarte der Stadt Fulda</Typography>
              </Divider>
              <img
                src={SensorMapFuldaQuerHoch}
                width={"100%"}
                alt="Sensorenkarte der Region Fulda"
                sx={{ overFlow: "auto" }}
              />
              <Divider component="div" role="presentation">
                <Typography variant="h1">Übersichtskarte der Gemeinde Hofbieber</Typography>
              </Divider>
              <img
                src={SensorMapHofbieberQuerHoch}
                width={"100%"}
                alt="Sensorenkarte der Region Hofbieber"
                sx={{ overFlow: "auto" }}
              />
              <Divider component="div" role="presentation">
                <Typography variant="h1">Übersichtskarte der Gemeinde Künzell </Typography>
              </Divider>
              <img
                src={SensorMapKuenzellQuerHoch}
                width={"100%"}
                alt="Sensorenkarte der Region Künzell"
                sx={{ overFlow: "auto" }}
              />
              <Divider component="div" role="presentation">
                <Typography variant="h1">Übersichtskarte der Gemeinde Petersberg </Typography>
              </Divider>
              <img
                src={SensorMapPetersbergQuerHoch}
                width={"100%"}
                alt="Sensorenkarte der Region Petersberg"
                sx={{ overFlow: "auto" }}
              />
            </Box>
          </TabPanel>
        </Box>
      </InfoWidget>
    </ScrollContainer>
  );
}
